<template>
  <div class="preference-type">
    <div>
      <div class="preference-type-single__items">
        <div
          v-if="!validValues || !validValues[0]"
          class="preference-type-single__no_value"
        >
          <div>
            No value found.
          </div>
          <AddButton
            type="secondary"
            text="Add value"
            small
            :ssr-icon="plus"
            @click="onAddClicked"
          />
        </div>
        <div
          v-for="(item, index) in validValues"
          :ref="el => { if (el) vForDivs[i] = el }"
          :key="index"
          data-arrow-navigable-item
          :class="validBaseType === 'String' ?
            'preference-type-single__item_string_wrapper' : 'preference-type-single__item'"
        >
          <div v-if="validBaseType === 'Integer'">
            <input
              :id="'valueInput' + index"
              :value="displayAsNumber(item.value)"
              placeholder="Type value..."
              class="preference-type-single__input_item"
              type="number"
              @input="item.value = stringFromNumber($event.target.value)"
            >
          </div>
          <div
            v-else-if="validBaseType === 'Boolean'"
            class="preference-type-single__item_boolean"
          >
            <!-- <span
              class="preference-type-single__item_boolean_name"
            >
              {{ preferenceValue.preferenceName }}
            </span> -->
            <checkbox
              v-model="item.value"
              :enabled="true"
            />
          </div>
          <div
            v-else-if="validBaseType === 'String'"
            class="preference-type-single__item_string"
          >
            <template
              v-if="customEditor === 'RtfEditor'"
            >
              <RtfViewer
                :name="preferenceName"
                :rtf="item.value"
                :edit="true"
                @rtf-uploaded="(content) => { item.value = content; }"
              />
            </template>
            <template
              v-else
            >
              <textarea
                id="preference-type-single__text-area"
                v-model="item.value"
                class="preference-type-single__text-area"
                @input="onTextareaInput"
              />
            </template>
          </div>
          <div v-else-if="validBaseType === 'Date'">
            <Datepicker
              ref="datepickerRef"
              v-model="item.value"
              :placeholder="String(item.value) === 'null' ? 'Select date' : String(item.value) || 'Select date'"
              :action-row-component="DatepickerActionRow"
              :format="datepickerFormat"
              :start-time="{
                hours: datepickerData.minimum.getHours(),
                minutes: datepickerData.minimum.getMinutes()
              }"
              :minutes-increment="5"
              :custom-props="datepickerData"
              :time-picker-component="CustomTimePicker"
              :month-change-on-scroll="false"
              @closed="onDatepickerClosed()"
            >
              <template #now-button="{ selectCurrentDate }">
                <span
                  title="Select current date"
                  @click="selectCurrentDate()"
                >
                  <button
                    type="button"
                    role="button"
                    class="btn btn--secondary"
                    @click="onNowClicked()"
                  >
                    <span
                      class="btn__inner cancel__button preference_value__dont-schedule-button"
                    >
                      <span
                        class="btn__label cancel__button-label"
                      >
                        Don't schedule
                      </span>
                    </span>
                  </button>
                </span>
              </template>
            </Datepicker>
          </div>
          <div
            v-else-if="validBaseType === 'Double' ||
              validBaseType === 'Decimal'"
          >
            <input
              :id="'valueInput' + index"
              :value="displayAsNumber(item.value)"
              placeholder="Enter value..."
              class="preference-type-single__input_item"
              type="number"
              step="0.1"
              @input="item.value = stringFromNumber($event.target.value)"
            >
          </div>
          <div v-else>
            Type not available
          </div>
          <div>
            <span
              v-if="!isInherited.value"
              class="preference-type-single__cross-icon"
              @click="onCrossClicked(item)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed, watch, ref, nextTick, reactive, onMounted,
} from 'vue';

import Checkbox from '@/components/shared/checkbox.vue';
import CustomTimePicker from '@/components/shared/datepicker-timepicker.vue';
import DatepickerActionRow from '@/components/shared/datepicker-action-row.vue';
import RtfViewer from '@/components/shared/rtf-viewer.vue';

import AddButton from '@ingka/button-vue';
import Icons from '@ingka/ssr-icon-vue/icons';

import { useUtilsStore } from '@/store/utils';

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  components: {
    Checkbox,
    AddButton,
    Datepicker,
    RtfViewer,
  },

  props: {
    preferenceValueDTO: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { toHash } = useUtilsStore();

    const vForDivs = ref([]);
    const datepickerRef = ref(null);
    const newItemAdded = ref(false);

    console.log('i single edit, dto', props.preferenceValueDTO);
    const validValues = computed(() => props.preferenceValueDTO.values);
    const validBaseType = computed(() => props.preferenceValueDTO.baseType);
    const isInherited = computed(() => props.preferenceValueDTO.inherited !== undefined);
    const customEditor = computed(() => props.preferenceValueDTO.customEditor);
    const preferenceName = computed(() => props.preferenceValueDTO.name);

    const displayAsNumber = (value) => Number(value);
    const displayAsBoolean = (value) => value === '1';
    const stringFromNumber = (value) => value.toString();
    const stringFromBoolean = (value) => (value ? '1' : '0');

    const onCrossClicked = (item) => {
      const index = validValues.value.indexOf(item);
      validValues.value.splice(index, 1);
    };

    const addZeroIfNeeded = (number) => (number < 10 ? `0${number}` : `${number}`);
    const dateAsUTC = (date) => date && new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    const datepickerData = reactive({
      minimum: dateAsUTC(new Date()),
      value: null,
      maximum: dateAsUTC(new Date('2030-12-31T23:59')),
    });

    // eslint-disable-next-line max-len
    const datepickerFormat = (date) => `${date.getFullYear()}-${addZeroIfNeeded(date.getMonth() + 1)}-${addZeroIfNeeded(date.getDate())}, ${addZeroIfNeeded(date.getHours())}:${addZeroIfNeeded(date.getMinutes())}`;

    const onDatepickerClosed = async () => {
      await nextTick();
      datepickerData.value = datepickerData.value < dateAsUTC(new Date()) ? null : datepickerData.value;
    };

    const onNowClicked = () => {
      datepickerRef.value.closeMenu();
      datepickerRef.value.clearValue();
    };

    const getDefaultInitialValue = () => {
      switch (validBaseType.value) {
        case 'Boolean':
        case 'Integer':
        case 'Decimal':
        case 'Double':
          return '0';
        default:
          return '';
      }
    };

    const onAddClicked = () => {
      console.log('valid', validValues);
      validValues.value.push(reactive({ value: getDefaultInitialValue() }));
      newItemAdded.value = true;
    };

    const onTextareaInput = () => {
      const inputElement = document.getElementById('preference-type-single__text-area');
      if (inputElement) {
        inputElement.style.height = 'auto';
        inputElement.style.height = `${inputElement?.scrollHeight}px`;
      }
    };

    onMounted(() => {
      onTextareaInput();
    });

    watch(() => validValues.value, async () => {
      console.log(vForDivs.value);
      if (newItemAdded.value) {
        newItemAdded.value = false;
        await nextTick();
        vForDivs.value.undefined.firstChild.focus();
      }
    },
    { deep: true });

    return {
      displayAsNumber,
      stringFromNumber,
      displayAsBoolean,
      stringFromBoolean,
      vForDivs,
      validValues,
      validBaseType,
      onCrossClicked,
      toHash,
      onAddClicked,
      plus: Icons.plus,
      isInherited,
      customEditor,
      preferenceName,
      onTextareaInput,
      datepickerRef,
      datepickerData,
      datepickerFormat,
      CustomTimePicker,
      DatepickerActionRow,
      onDatepickerClosed,
      onNowClicked,
    };
  },
};
</script>

<style scoped>
.preference-type-single__items {
  display: block;
  max-width: 52rem;
}

.preference-type-single__no_value {
  display: block;
}

.preference-type-single__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 0;
}

.preference-type-single__item_string_wrapper {
  display: flex;
  flex-direction: row;
  align-items: top;
  padding: 0.5rem 1.5rem 0.5rem 0;
}

.preference-type-single__item_boolean {
  display: flex;
}

.preference-type-single__item_boolean_name {
  padding-right: 1rem;
}

.preference-type-single__cross-icon {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  content: '';
  background: transparent;
  background-image: url("../../../../assets/svg/cross-light.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.preference-type-single__cross-icon:hover {
  background-image: url("../../../../assets/svg/cross.svg");
}

.preference-type-single__item_string {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;
}

.preference-type-single__text-area {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;
  overflow: hidden;
  color: var(--colour-text-default);
  resize: none;
  background: var(--color-bg-default);
}
</style>
